'use client';
import { IResultProductsCatalog } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';

export const getWishList = async (
	limit: number,
	offSet: number
): Promise<IResultProductsCatalog> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/storages/getWishListUserLogged/${offSet}/${limit}`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);
		const result = await promise.json();
		console.log('🚀 ~ result:', result);

		return {
			status: 200,
			docs: [...result.docs] || [],
			total: result.metadata ? result.metadata?.total : 0,
		};
	} catch (error) {
		throw new Error((error as Error).message);
	}
};
