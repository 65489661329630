import { CustomObject } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';

export const signIn = async (
	body: CustomObject
): Promise<{
	status: number;
	data: string;
	message: string;
}> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(`${process.env['NEXT_PUBLIC_API_URL']}/login`, {
			method: 'post',
			credentials: 'include',
			headers: headers,
			body: JSON.stringify(body),
		});
		if (promise?.status === 200) {
			return {
				status: 200,
				data: 'loginSuccess',
				message: 'loginSuccess',
			};
		} else if ([401, 403].includes(promise.status)) {
			return {
				status: promise.status,
				data: 'userOrPassInc',
				message: 'userOrPassInc',
			};
		} else if (promise?.status === 405) {
			return {
				status: promise.status,
				data: 'userNoActive',
				message: 'userNoActive',
			};
		}

		return { status: 500, data: 'internalError', message: '' };
	} catch (error) {
		return { status: 500, data: 'internalError', message: '' };
	}
};

export const signOut = async (): Promise<boolean> => {
	'use client';
	try {
		const headers = await commonRequestHeaders();
		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL']}/logout`,
			{
				method: 'get',
				credentials: 'include',
				headers: headers,
			}
		);
		await promise.json();

		return true;
	} catch (error) {
		return false;
	}
};
